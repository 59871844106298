import Navbar from './Navbar';
import Home from './Home';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Details from './Details';
import NotFound from './NotFound';
import {MyContextProvider } from './store/MyContext'
import About from './About';

function App() {

  return (
<MyContextProvider>

    <Router>
      <div className="App">
  
        <Navbar />         
        {/* <div className="content">*/}
        
          <Switch>
          <Route exact path="/About" component={About}/>
            <Route exact path="/Home" component={Home}/>
            <Route exact path="/" component={Details}/>
            
            <Route path="/Create/:id">

          
            </Route>

            {/* <Route path="/:id">
              <Details />
              </Route> */}
            <Route path="*">
              <NotFound />
            </Route>
          </Switch>
        
        </div>
      
    </Router>
    
    </MyContextProvider>

  );
}

export default App;


