import React, { useState,useContext,useRef} from 'react'
import './logIn.css';
import MyContext, {root} from "./store/MyContext";
import { Link  ,useHistory} from "react-router-dom";
import {FaceSVG,CameraSVG} from "./store/SVGFiles";
//const ReactCSSTG = React.addons.CSSTransitionGroup;

// Main app

function Account({setProfile}){
 const myCtx = useContext(MyContext);
  const [session, setSession] = useState(myCtx.user.name===undefined?"LogIn":"Profile");

 


  const user ={name:"asdasd",token:"hj3434"};

 console.log(myCtx.user)
  return ( 
   <div className="Modal" key="modal"   > 
   <button className="signIn"  style={{right:"10px",top:"10px" , position: "absolute" ,width: "20px"}} onClick={()=>setProfile(false)} >X</button>
        <Logo  />
       
        {session==="LogIn"&& <LogIn setSession={setSession} myCtx={myCtx}/>}
        {session==="Profile"&& <Profile  myCtx={myCtx} setSession={setSession}/>}
        {session==="Register"&& <RegistrationForm  myCtx={myCtx} setSession={setSession}/>}

        
    

       
  </div>

  )
}

function Profile({myCtx,setSession}) {
  
console.log(myCtx.user)
  return ( 
<div className="form-body">
<div className="Input"></div>
<div style={{hight:120,width:120}} ><UploadPhoto item={myCtx.user} myCtx={myCtx}/>
 </div> 
<div style={{fontSize: "30px"}}>{myCtx.user.name}</div> 
<div>  {"Email : "+myCtx.user.email} </div>




<div>  {"Bio : _"} </div>
<div className="footer">
  
<button className="signIn" onClick={()=>{myCtx.endUser();setSession("LogIn") }} >Log out</button>

</div>
</div>
  ) 
}


function LogIn({myCtx,setSession}) {
  const user = {};
  const massRef=useRef(); 
  const handleSubmit  = () => {
    massRef.current.innerHTML="Loading ..."
    fetch(root+"/api/login",{
      method: "POST",
      headers: { "Content-Type": "application/json" },
     // mode:"no-cors" ,
      body: JSON.stringify(user),
    }).then((res) => {
      if (!res.ok) {throw Error("can't login ");}
      return res.json();
    }).then((data) => {console.log("Loged In") 
    massRef.current.innerHTML="Loged In";
    user.id=data.user.id;
    user.name =data.user.name;
    user.email =data.user.email;
    user.pic=data.user.pic;
    user.bio=data.user.bio;
    user.token=data.token;


    myCtx.setUser(user)
    setSession("Profile")
  })
      .catch((err) => { console.log(err.message) ; massRef.current.innerHTML=err.message});

    
  }

  // handleRemount(e) {
    
  // }
  
    
    return (


    
     <> <div className="form-body">
       <label ref={massRef}> </label> 
        <div className="Input"></div>
    
        {/* <label htmlFor={this.props.name}></label> */}
         
          <div className="email">
          <label className="form__label" htmlFor="email">Email </label>
          <input type="text" name="email" placeholder="email"  onChange = {(e) =>{user.email=e.target.value}} />

          </div>
          <div className="password">
          <label className="form__label" htmlFor="password">Password </label>
          <input type="password" name="password" placeholder="password"  onChange = {(e) =>{user.password=e.target.value}}  />
         
          </div>

      

     
        </div>
        <div className="footer">

        <div> 
        <button className="signIn" onClick={()=>handleSubmit()} style={{ background:"#bbddff",width:"120px"}}> Log in</button> 
        </div><a></a>
        <div className="Input"></div>
        <a></a>
        <div>
        <button className="signIn" onClick={()=>setSession("Register")} >Sign Up</button>
        </div>
         </div>
        </> 
    
    );
  }





// Fake logo
class Logo extends React.Component {
  render() {
    return (
      <div className="logo">
        <i className="fa fa-bug" aria-hidden="true"></i>
        A r e t <span>E n g</span>
      </div>
    );
  }
}







//RegistrationForm
function RegistrationForm({myCtx,setSession}) {
    

  const massRef=useRef(); 
  const user = {};
  // const [firstName, setFirstName] = useState(null);
  // const [lastName, setLastName] = useState(null);
  // const [email, setEmail] = useState(null);
  // const [password,setPassword] = useState(null);
  // const [confirmPassword,setConfirmPassword] = useState(null);

  const handleInputChange = (e) => {
      const {id , value} = e.target;
      if(id === "firstName"){
        user.name=value
          //setFirstName(value);
      }
      if(id === "lastName"){
        user.lastName=value 
        // setLastName(value);
      }
      if(id === "email"){
        user.email=value  
        //setEmail(value);
      }
      if(id === "password"){
        user.password=value
        //  setPassword(value);
      }
      if(id === "confirmPassword"){
        user.password_confirmation=value  
        //setConfirmPassword(value);
      }

  }

  const handleSubmit  = () => {
  
     
    massRef.current.innerHTML="Loading ..."
    fetch(root+"/api/register",{
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(user),
    }).then((res) => {
      if (!res.ok) {throw Error("can't register part");}
      return res.json();
    }).then((data) => {console.log("registered") ;
    massRef.current.innerHTML="registered"
    user.name =data.user.name;
    user.email =data.user.email;
    user.token=data.token;
    myCtx.setUser(user)
    setSession("Profile")
  })
      .catch((err) => { console.log(err.message); massRef.current.innerHTML=err.message });

}

 

  

  return(
   <>
          <div className="form-body">
          <div className="Input"></div>
          <label ref={massRef}> </label> 
              <div className="username">
                  <label className="form__label" htmlFor="firstName">Display name * </label>
                  <input className="form__input" type="text"  onChange = {(e) => handleInputChange(e)} id="firstName" placeholder="First Name"/>
              </div>
              <div className="lastname">
                  <label className="form__label" htmlFor="lastName">Last Name(Optional) </label>
                  <input  type="text" name="" id="lastName"   className="form__input" onChange = {(e) => handleInputChange(e)} placeholder="LastName"/>
              </div>
              <div className="email">
                  <label className="form__label" htmlFor="email">Email *</label>
                  <input  type="email" id="email" className="form__input"  onChange = {(e) => handleInputChange(e)} placeholder="Email"/>
              </div>
              <div className="password">
                  <label className="form__label" htmlFor="password">Password *</label>
                  <input className="form__input" type="password"  id="password" onChange = {(e) => handleInputChange(e)} placeholder="Password"/>
              </div>
              <div className="confirm-password">
                  <label className="form__label" htmlFor="confirmPassword">Confirm Password *</label>
                  <input className="form__input" type="password" id="confirmPassword"  onChange = {(e) => handleInputChange(e)} placeholder="Confirm Password"/>
              </div>
          </div>
          <div className="footer">
              <button  className="signIn" onClick={()=>handleSubmit()} type="submit" >Register</button> 
          </div>
  
     </>
  )       
}





export default Account




function About() {

  return (
    <div className="Modal"  style={{padding:"30px"}}>
    <div></div>
    Hello there <div> :) </div>  <div></div> I am Ted ,This page is under construction by me , it is kind of 3D explore for parts and hardware by providing complete knock down of Assemblies as much as possible , 
 you can explore   exploded views and disassembly , it is also  possible to take parts and build new assemblies .few file importing options are also available  . <div></div>kinda cool ha ?
    <div></div>Hey ,if you like this please let me know at <div></div><div></div>  -- theodros@areteng.com-- <div>(416)569-3454 </div> <div>Toronto</div>.;-)
   

    </div>


  )
}

export  {About}
// function savePhoto(myCtx,item){
//   gl.domElement.toBlob(
//     function(blob) {
     
//       var a = document.createElement('a')
//       var url = URL.createObjectURL(blob)
//       a.href = url
// var file = new File([blob],'canvas.jpg');
// var data = new FormData();
// data.append("x_nd", x_nd);
// data.append("y_nd", y_nd);
// data.append("z_nd", z_nd);
// data.append("File", file);
// data.append("Name", "canvas.jpg");

// fetch(root+'/api/savePhoto/'+item.id, {
//   method: "POST",
//   headers: {
//   'Authorization': 'Bearer ' + myCtx.user.token},
// //  headers: {'Content-Type': 'multipart/form-data'},
//   body: data,
// }).then((res) => {
//   if (!res.ok) {throw Error("can't create mige");}
//   return res.json();
// }).then((d) => {item.picFile=d.picFile})
// .catch((err) => { console.log(err.message) });

// },
// 'image/jpg',
// 0.9
// )

// }


function UploadPhoto ({item,myCtx}) {
  const [userInfo, setuserInfo] = useState({
    file:[],
    filepreview:null,
});
const [invalidImage, setinvalidImage] = useState(null);
const handleInputChange = (event) => {

let reader = new FileReader();  
 const imageFile = event.target.files[0];
        const imageFilname = event.target.files[0].name;

        if (!imageFile) {
         setinvalidImage('Please select image.');
          return false;
        }
    
        if (!imageFile.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG|gif|jfif)$/)) {
         setinvalidImage('Please select valid image JPG,JPEG,PNG');
          return false;
        }
        reader.onload = (e) => {
          const img = new Image();
          img.onload = () => {

//------------- Resize img code ----------------------------------
           var canvas = document.createElement('canvas');
           var ctx = canvas.getContext("2d");
           ctx.drawImage(img, 0, 0);

           var MAX_WIDTH =120;
           var MAX_HEIGHT =120;
           var width = img.width;
           var height = img.height;

           if (width > height) {
             if (width > MAX_WIDTH) {
               height *= MAX_WIDTH / width;
               width = MAX_WIDTH;
             }
           } else {
             if (height > MAX_HEIGHT) {
               width *= MAX_HEIGHT / height;
               height = MAX_HEIGHT;
             }
           }
           canvas.width = width;
           canvas.height = height;
           var ctx = canvas.getContext("2d");
           ctx.drawImage(img, 0, 0, width, height);
           ctx.canvas.toBlob((blob) => {
             const file = new File([blob], imageFilname, {
                 type: 'image/jpeg',
                 lastModified: Date.now()
             });
             setuserInfo({
                ...userInfo,
                file:file,
                filepreview:URL.createObjectURL(imageFile),
           })
           }, 'image/jpeg', 1);
         setinvalidImage(null)
         };
          img.onerror = () => {
                setinvalidImage('Invalid image content.');
            return false;
          };
          //debugger
          img.src = e.target.result;
        };
        reader.readAsDataURL(imageFile);

}; 
const [isSucces, setSuccess] = useState(null);
const submit = async () =>{
  var data = new FormData();
  data.append('File', userInfo.file);
fetch(root+'/api/uploadPhoto/'+item.id, {
  method: "POST",
  headers: {
  'Authorization': 'Bearer ' + myCtx.user.token},
//  headers: {'Content-Type': 'multipart/form-data'},
  body: data,
}).then((res) => {
  if (!res.ok) {throw Error("can't create mige");}
  return res.json();
}).then((d) => {item.pic=d.pic 
  myCtx.setUser(item)
})
.catch((err) => { console.log(err.message) });

}

return(
  <div >


  <div style={{hight:120,width:120}}>
      {isSucces !== null ? isSucces :null }
      {invalidImage !== null ? invalidImage:null }
      <div style={{position:"absolute",hight:24,width:24}} >
          <label >
          <input type="file" className="form-control" name="upload_file" onChange={handleInputChange} />
          <CameraSVG/>
          </label>
      </div>

      <div>
        
      </div>
  </div>

  {userInfo.filepreview !== null ?<>
      <img className="previewimg" style={{  maxWidth:"120px"}} src={userInfo.filepreview} alt="UploadImage" />
  <button type="submit" className="signIn" onClick={()=>submit()}> Save </button>
      </> 
  :(myCtx.user.pic!=="null"?<img src={root+'/images/'+myCtx.user.pic} alt="Assembly"  className="row__imgs"
  />:<FaceSVG/>)}
</div>
)
}



