import React from "react";

function Hook() {
    return (
<svg version="1.1" 
xmlns="http://www.w3.org/2000/svg" 
xlink="http://www.w3.org/1999/xlink" 
x="0px" y="0px" 
viewBox="0 0 1000 1000" enableBackground="new 0 0 1000 1000" space="preserve">
<g><g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"> 

{/* <path fill="#555" d="M3307.4,4466.7l-553.3-553.3l300.6-1661.9c164.7-915.2,304.4-1679.2,310.2-1696.4c9.6-32.5,103.4-34.5,1635.1-34.5c1531.7,0,1625.6,1.9,1635.1,34.5c5.7,17.2,143.6,781.2,310.2,1696.4l300.6,1661.9l-553.3,553.3L6139.2,5020H5000H3860.8L3307.4,4466.7z" />
<path fill="#555" d="M3991-239.6c3.8-9.6,120.6-360,258.5-781.2l250.8-765.9l430.8-428.9c517-517,538-547.6,538-825.2c0-158.9-5.7-195.3-51.7-287.2c-63.2-130.2-185.7-256.6-312.1-323.6c-86.2-44-116.8-49.8-296.8-49.8c-298.7,0-308.3,5.7-913.3,608.9c-275.7,275.7-507.4,495.9-511.2,490.2c-28.7-28.7-68.9-314-68.9-488.2c0-444.2,147.4-823.3,446.1-1148.8c551.4-597.4,1472.4-716.1,2150.2-273.8c348.4,227.9,574.4,526.5,706.5,936.3c46,139.8,53.6,204.9,63.2,451.9c7.7,250.8,3.8,308.3-32.5,448c-24.9,101.5-65.1,197.2-105.3,258.5c-74.7,111.1-329.3,375.3-714.2,741l-271.9,258.5l210.6,553.3c116.8,304.4,220.2,572.5,229.7,597.4l17.2,42.1H4998.1C4439-226.2,3987.1-232,3991-239.6z" /> */}


{/* <path fill="#555" d="M4352.9,4987.5c-28.7-30.6-32.5-90-32.5-419.3v-384.8l105.3,68.9l105.3,68.9v319.7c0,283.3-3.8,321.6-34.5,348.4C4450.6,5031.5,4391.2,5029.6,4352.9,4987.5z"/>
<path fill="#555" d="M5501.6,4987.5c-28.7-30.6-32.6-93.8-32.6-497.8v-461.4l-91.9,46c-292.9,147.4-677.7,103.4-903.6-103.4c-70.8-63.2-522.6-777.3-509.2-804.1c1.9-5.7,114.9-247,250.8-532.2L4464,2112h109.1h111v-275.7v-275.7l312-300.6c170.4-164.6,333.1-335,361.8-377.1c109.1-158.9,84.2-381-57.4-518.8l-72.7-70.8l-70.8,47.9c-93.8,65.1-168.5,78.5-241.2,42.1c-30.6-15.3-444.2-308.2-915.1-649l-855.8-618.4l185.7-5.7l187.6-5.8l461.4,333.1l463.3,333.1l93.8-67c277.6-199.1,729.4-229.7,1054.9-70.8l93.8,45.9l398.2-287.2l398.2-287.2l187.6,5.8l185.7,5.7l-472.9,340.8c-258.4,187.6-476.7,348.4-482.4,359.9c-7.7,9.6,23,59.4,67,109.1c105.3,118.7,206.8,321.6,241.2,480.5c107.2,509.3-116.8,940-687.3,1313.3l-202.9,134l-1.9,130.2V2112h111h109.1l247,518.8c135.9,285.3,246.9,528.4,246.9,541.8c0,13.4-78.5,153.2-174.2,312.1l-176.1,287.2v593.5c0,551.4-1.9,595.4-34.5,624.1C5599.2,5031.5,5539.9,5029.6,5501.6,4987.5z M5093.8,2778.2c109.1-97.6,49.8-264.2-93.8-264.2c-103.4,0-155.1,63.2-145.5,181.9c5.7,51.7,90,124.4,145.5,124.4C5024.9,2820.3,5067,2801.2,5093.8,2778.2z"/>
<path fill="#555" d="M4113.6,1143.3c-51.7-36.4-51.7-55.5-1.9-172.3c36.4-80.4,42.1-124.4,38.3-371.4c-1.9-269.9,24.9-459.5,65.1-472.9c32.5-9.6,402,273.8,396.3,302.5c-103.4,419.3-227.8,654.7-381,717.9C4155.7,1179.6,4165.3,1179.6,4113.6,1143.3z"/> */}
<path fill="#555" d="M2282.6,4977.8c-184.3-80.6-304.1-297.2-281-502.2c13.8-110.6,76-209.6,711.8-1165.6c382.4-575.9,730.2-1075.8,771.7-1110.4c41.5-36.9,112.9-78.3,161.3-89.8c126.7-36.9,2600.8-34.6,2704.5,2.3c43.8,13.8,108.3,46.1,142.8,71.4c78.3,53,1439.8,2084.8,1485.8,2216.1c71.4,205-39.2,453.8-248.8,559.8l-101.4,50.7h-2640C2856.2,5007.7,2337.9,5003.1,2282.6,4977.8z"/>
<path fill="#555"d="M4501,815.1l-6.9-762.5L4302.9-2.7c-382.4-110.6-737.2-322.5-1032-617.4c-956-956-958.3-2485.6-9.2-3446.2c693.4-700.3,1723.1-912.2,2649.2-548.3c320.2,126.7,601.2,320.2,863.9,596.6c403.1,423.9,601.2,884.6,665.8,1536.5l11.5,119.8h-481.5h-481.5L6473-2493c-41.5-347.8-188.9-649.6-433.1-896.1c-292.5-292.6-635.8-435.4-1038.9-433.1c-271.8,2.3-463,46.1-684.2,163.6c-520.6,271.8-827,808.6-794.8,1389.1c20.7,382.4,159,695.7,430.8,967.5c175.1,172.8,343.2,278.7,580.5,364c149.7,53,211.9,59.9,555.2,69.1l384.7,9.2V359v1218.6h-483.8h-483.8L4501,815.1z"/>


</g></g> 

</svg>

    );
  }
  export {Hook};    
 
function Swap(props) {
  return (
<svg {...props} width="20px" height="20px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
  <path fill="#555" d="M19.29894,13.097555 C19.9200379,13.097555 20.2332042,13.8469628 19.7969407,14.2892722 L14.369746,19.7916791 C14.0983279,20.0668585 13.6553376,20.0697948 13.3802994,19.7982374 C13.1052612,19.52668 13.1023265,19.0834622 13.3737445,18.8082827 L17.6255116,14.497593 L0.703482198,14.497593 C0.317070803,14.497593 0.00382247492,14.1841838 0.00382247492,13.797574 C0.00382247492,13.4109642 0.317070803,13.097555 0.703482198,13.097555 L19.29894,13.097555 Z M6.61970059,0.201762638 C6.89473881,0.473320047 6.89767354,0.91653784 6.62625551,1.19171729 L2.37448841,5.50240698 L19.2965178,5.50240698 C19.6829292,5.50240698 19.9961775,5.81581617 19.9961775,6.20242599 C19.9961775,6.58903581 19.6829292,6.902445 19.2965178,6.902445 L0.701060011,6.902445 C0.0799621139,6.902445 -0.233204177,6.15303716 0.203059275,5.7107278 L5.63025404,0.208320918 C5.90167207,-0.0668585286 6.34466238,-0.0697947706 6.61970059,0.201762638 Z"/>
</svg>    );
  }
  export {Swap}; 

  function Hooked() {
    return ( 
<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1000 1000" enableBackground="new 0 0 1000 1000" space="preserve">
<g><g transform="translate(0,512) scale(.1,-.1)">
<path fill="#555"  d="M4352.9,4987.5c-28.7-30.6-32.5-90-32.5-419.3v-384.8l105.3,68.9l105.3,68.9v319.7c0,283.3-3.8,321.6-34.5,348.4C4450.6,5031.5,4391.2,5029.6,4352.9,4987.5z"/>
<path fill="#555" d="M5501.6,4987.5c-28.7-30.6-32.6-93.8-32.6-497.8v-461.4l-91.9,46c-292.9,147.4-677.7,103.4-903.6-103.4c-70.8-63.2-522.6-777.3-509.2-804.1c1.9-5.7,114.9-247,250.8-532.2L4464,2112h109.1h111v-275.7v-275.7l312-300.6c170.4-164.6,333.1-335,361.8-377.1c109.1-158.9,84.2-381-57.4-518.8l-72.7-70.8l-70.8,47.9c-93.8,65.1-168.5,78.5-241.2,42.1c-30.6-15.3-444.2-308.2-915.1-649l-855.8-618.4l185.7-5.7l187.6-5.8l461.4,333.1l463.3,333.1l93.8-67c277.6-199.1,729.4-229.7,1054.9-70.8l93.8,45.9l398.2-287.2l398.2-287.2l187.6,5.8l185.7,5.7l-472.9,340.8c-258.4,187.6-476.7,348.4-482.4,359.9c-7.7,9.6,23,59.4,67,109.1c105.3,118.7,206.8,321.6,241.2,480.5c107.2,509.3-116.8,940-687.3,1313.3l-202.9,134l-1.9,130.2V2112h111h109.1l247,518.8c135.9,285.3,246.9,528.4,246.9,541.8c0,13.4-78.5,153.2-174.2,312.1l-176.1,287.2v593.5c0,551.4-1.9,595.4-34.5,624.1C5599.2,5031.5,5539.9,5029.6,5501.6,4987.5z M5093.8,2778.2c109.1-97.6,49.8-264.2-93.8-264.2c-103.4,0-155.1,63.2-145.5,181.9c5.7,51.7,90,124.4,145.5,124.4C5024.9,2820.3,5067,2801.2,5093.8,2778.2z"/>
<path fill="#555" d="M4113.6,1143.3c-51.7-36.4-51.7-55.5-1.9-172.3c36.4-80.4,42.1-124.4,38.3-371.4c-1.9-269.9,24.9-459.5,65.1-472.9c32.5-9.6,402,273.8,396.3,302.5c-103.4,419.3-227.8,654.7-381,717.9C4155.7,1179.6,4165.3,1179.6,4113.6,1143.3z"/>
{/* <path fill="#555" d="M2310.2-2961.3V-4780H5000h2689.8v1818.7v1818.7H5000H2310.2V-2961.3z"/> */}
</g></g>
</svg>

    )}
    export {Hooked}; 
    
function UpSVG() {
      return (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="64px" height="20px" viewBox="0 0 64 20" enableBackground="new 0 0 64 20" space="preserve">
<g>
	<polyline fill="none" stroke="#5555" stroke-width="2" stroke-linejoin="bevel" stroke-miterlimit="10" points="4,15,32,0
		60,15 	"/>
<polyline fill="none" stroke="#5555" stroke-width="2" stroke-linejoin="bevel" stroke-miterlimit="10" points="4,20,32,5
		60,20 	"/>

</g>
</svg>
  )}
  export {UpSVG}; 

function FallBackSVG() {
    return (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
  viewBox="0 0 1000 1000" enableBackground="new 0 0 1000 1000" space="preserve">
<g><path fill="#5f6368" d="M860.3,374.7l124.4-124.4c4-3.9,5.8-9.4,5.2-14.8c-0.8-5.6-4-10.4-8.7-13.2L645.7,28.5c-6.9-4-15.7-2.8-21.3,2.9L500,155.8L375.6,31.5c-5.7-5.7-14.4-6.9-21.4-2.9L18.8,222.4c-4.9,2.8-8,7.6-8.7,13.2c-0.7,5.3,1,10.9,4.9,14.8l124.4,124.4L15.1,499.3c-3.9,3.9-5.7,9.4-4.9,14.8c0.6,5.4,3.8,10.3,8.7,13l128,74v161.2c0,6.4,3.4,12.2,8.8,15.4l335.5,193.9c2.7,1.7,5.8,2.3,8.8,2.3c3,0,6.1-0.7,8.8-2.3l335.4-193.9c5.4-3.2,8.8-9,8.8-15.4V601.1l128.1-74c4.7-2.8,7.9-7.6,8.7-13c0.7-5.5-1.2-10.9-5.2-14.8L860.3,374.7L860.3,374.7z M799.9,372.5L500,545.8L200,372.5l224.9-130l75.1-43.3l271.3,156.6L799.9,372.5L799.9,372.5z M639.8,63.8l303.6,175.6l-111,111l-18.2-10.5L528.7,174.9L639.8,63.8L639.8,63.8z M360.2,63.8l111.1,111.1L167.5,350.3l-111.1-111L360.2,63.8L360.2,63.8z M167.5,394.6l303.6,175.6l-111,111L173.3,573.2c-0.1,0-0.1,0-0.1,0L56.4,505.7L167.5,394.6L167.5,394.6z M182.2,619.3l172,99.3c2.8,1.7,5.8,2.4,8.8,2.4c0.4,0,0.6-0.4,1-0.4c4.1-0.1,8.3-1.5,11.5-4.7l106.8-106.8v314.2L182.2,749.8L182.2,619.3L182.2,619.3z M817.6,749.8l-300,173.4V609.1l106.6,106.8c3.1,3.1,7.2,4.4,11.4,4.7c0.4,0,0.7,0.4,1,0.4c3.1,0,6.1-0.7,8.8-2.4l172-99.3V749.8L817.6,749.8z M639.7,681.1l-111-111l303.7-175.6l111,111.1L639.7,681.1L639.7,681.1z"/></g>
</svg>
)}
export {FallBackSVG}; 


function FaceSVG() {
  return (

<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"  fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"/></svg>
)}
export {FaceSVG}; 


function CameraSVG() {
  return (

<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path d="M21 6h-3.17L16 4h-6v2h5.12l1.83 2H21v12H5v-9H3v9c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2zM8 14c0 2.76 2.24 5 5 5s5-2.24 5-5-2.24-5-5-5-5 2.24-5 5zm5-3c1.65 0 3 1.35 3 3s-1.35 3-3 3-3-1.35-3-3 1.35-3 3-3zM5 6h3V4H5V1H3v3H0v2h3v3h2z"/></svg>
)}
export {CameraSVG}; 
