import React from 'react'
import './logIn.css';
function About() {

  return (
    <div className="Modal"  style={{padding:"30px"}}>
    <div></div>
    Hello there <div> :) </div>  <div></div> This page is under construction  . <div></div>
    <div></div><div></div><div></div>  -- info@areteng.com-- <div> </div> <div></div>
   

    </div>


  )
}

export default About