import {root} from "../../store/MyContext";
import {FallBackSVG} from "../../store/SVGFiles";

//import "./SideBar.css"

const SidebarItem=({Item,onClick})=> {
 
    return (Item.created_at!=="delete" &&
      
        <div  onClick={onClick} className="part" >
            <div className="name" style={Item.updated_at==="not" ? {color:'orange','fontStyle':'italic'}:{overflow: 'hidden'} }>{Item.name} </div>
            <div style={Item.updated_at==="not" ? {color:'orange','fontStyle':'italic'}:{overflow: 'hidden'} }>Part Number:{Item.id}:{Item.id} </div>
            <div style={Item.updated_at==="not" ? {color:'orange','fontStyle':'italic'}:{overflow: 'hidden'} }>{Item.updated_at==="not"?"Not Saved!" :new Date(Item.updated_at).toISOString().split('T')[0]} </div>
           {Item.picFile?<img src={root+'/images/'+Item.picFile } className="Prvimages"  alt="NO" />:
           <div className="Prvimages" ><FallBackSVG/></div>
           } 
       </div>)
     
 
}

export default SidebarItem


function imageOnErrorHandler(e){
    e.currentTarget.src = ('./icon/Fallback.svg')
   } 