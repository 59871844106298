import {  Link } from "react-router-dom";
import MyContext,{root} from "../../store/MyContext";
import {  useContext ,useState} from "react";
import { DefaultEditor } from 'react-simple-wysiwyg';
import SaveIcon from '@mui/icons-material/Save';
import ClearIcon from '@mui/icons-material/Clear';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';

const SidebarDatum=({Item,uniAssembly})=> {
  
    const myCtx = useContext(MyContext);
    const [isLoading, setIsLoading] = useState(false);

    

    return (
  <div disabled={isLoading} className="SeletedPart"   style={{height:"160px"}}  >
   
          {myCtx.isEditOn ?(<>
{/* <form style={{margin:"0px",padding:"2px 0px ", borderRadius:"10px"}} >       */}
  <input type="text" defaultValue={Item.title}  onChange={(e) =>{Item.title=e.target.value;Item.updated_at="not"}} 
            style={ Item.created_at==="not"?{color:'orange','fontStyle':'italic'}:{color:'black'}}/>     
<select defaultValue={Item.type} onChange={(e) =>{Item.type=e.target.value;Item.updated_at="not"}} >
  <option value="dimension">Dimension</option>
  <option value="shopping">Shopping</option>
  <option value="maintenance">Maintenance </option>
  <option value="manufacturer">Manufacturer</option>
  <option value="documentation">Documentation</option>
  </select>
  <div className="content">
 <Wysiwyg className="details" Item={Item}/>                
 {/* <DefaultEditor value={Item.details} onChange={(e) =>{Item.details=e.target.value;Item.updated_at="not"}} 
            style={ Item.created_at==="not"?{color:'orange','fontStyle':'italic'}:{color:'black'}}/> */}

        <div   style={{right:200}} onClick={() =>{
        Item.created_at==="not"?createDatum(Item,uniAssembly.id,setIsLoading):savePart(Item,setIsLoading);
        setIsLoading(true) }}  >

        {isLoading?<HourglassTopIcon className="itemWidget" style={{right:200, bottom:0}}/>:
        <SaveIcon className="itemWidget" style={{right:200, bottom:0}}/>}</div>

         {/* <ContentCutIcon className="itemWidget"  style={{right:230}} onClick={() =>{myCtx.setClipeBoard(Item);cutPart(Item,myCtx)}} />*/}
        <ClearIcon className="itemWidget"  style={{right:260, backgroundColor:"red" , bottom:0}} onClick={() =>{destroy(Item,myCtx)}} /> 
     </div> 
{/* </form> */}

</>
        ):(<>



          
          
<div className="title"style={Item.updated_at==="not" ? {color:'orange','fontStyle':'italic'}:{overflow: 'hidden'} }>{Item.title} </div>

<div className="content" style={Item.updated_at==="not" ? {color:'orange','fontStyle':'italic'}:{overflow: 'hidden'} }>
<div className="details" dangerouslySetInnerHTML={{__html: Item.details}} ></div>
<div className="date"  >{Item.updated_at==="not"?"Not Saved!" :new Date(Item.updated_at).toISOString().split('T')[0]} </div>
<div className="author" ><Link to="/About" >Theodros Galt</Link></div>
<div className="rating" >$</div>

</div>
          
          
          </>
          
          
          
          
          )}
          {/* <PrecisionManufacturingOutlinedIcon className="itemWidget"  style={{right:170}} onClick={() =>{myCtx.setClipeBoard(Item);myCtx.geoUpdate()}} /> 
          
          <img src={root+'/images/hook.svg' }  className="itemWidget" alt="hook"  style={{right:170,padding:2}} onClick={() =>{myCtx.setClipeBoard(Item);myCtx.geoUpdate()}} /> 

          <img src={root+'/images/'+Item.picFile }  className="linkImages"  onError={imageOnErrorHandler}  alt="linkImages" onClick={() => {
            
            myCtx.isEditOn?myCtx.setGrabSt("request"):history.push("/?uniAssembly="+Item.id);
            
            
            }} /> */}


    </div>
    )
}
export default SidebarDatum



const Wysiwyg=({Item,uniAssemblyID})=> {
  const [html, setHtml] = useState(Item.details);
  Item.details=html
return(
<DefaultEditor value={html}  onChange={(e) =>{setHtml(e.target.value);Item.updated_at="not"}} 
            style={ Item.created_at==="not"?{color:'orange','fontStyle':'italic'}:{color:'black'}}/>

)
}
function savePart(part,setIsLoading){
    const partInst = {...part,name:"datum"}
  
    delete partInst.updated_at;
    delete partInst.created_at;
    delete partInst.id;
    //delete partInst.modelFile;
    

    fetch(root+"/api/saveDatum/" + part.id,{
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(partInst),
      }).then( (res) => {
        if (!res.ok) {console.log(res.json()) ; throw Error("can't updat part");}
        
        return res.json();
      }).then((data) => 
      
      {
        part.updated_at=data.updated_at;
        setIsLoading(false);
     })
    .catch((err) => { console.log(err.message) });
  

}

function createDatum(Dt,uniAssemblyID,setIsLoading){
    const datum = {...Dt};
    datum.uniAssemblyID=uniAssemblyID

console.log(JSON.stringify(datum));  

    fetch(root+"/api/createDatum",{
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(datum)
      }).then((res) => {
        if (!res.ok) {console.log(res.statusText); throw Error("can't create datum");}
        return res.json();
      }).then((data) => {Dt.id=data.id;
        Dt.updated_at=data.updated_at;
        Dt.created_at=data.created_at;
        setIsLoading(false)
  })
      .catch((err) => { console.log(err.message) });
    
  
  }


  function destroy(part,myCtx){
    let text = "Delete the item permanently ?";
    if (window.confirm(text)) {
    if (part.created_at!=="not"){
    fetch(root+'/api/destroyDatum/'+part.id, 
    { method: 'DELETE' })
    .then((res) => {
      if (!res.ok) {throw Error("can't delet part");}
      return res.json();
    }).then(() => {
     
  }).catch((err) => { console.log(err.message) });
  
  }
  part.created_at="delete"
  myCtx.setDatumSt(null)
  
  }}

function imageOnErrorHandler(e){
  e.currentTarget.src = ('./icon/Fallback.svg')
 } 

