import { useParams ,useHistory} from "react-router-dom";
import "./Components/Sidebar/SideBar.css";
import useFetch from "./store/useFetch";
import ThreeCanvas from "./ThreeCanvas";
import LoadGeometry from "./store/LoadGeometry";
import {  useContext ,useEffect,useState} from "react";

import SideBar from "./Components/Sidebar/SideBar";

import MyContext, {root} from "./store/MyContext";
import Box from "./Components/Box";

const Details = ({location}) => { 
  const { id } = useParams();
  const myCtx = useContext(MyContext);
  
const history = useHistory();

let uniAssembly = new URLSearchParams(location.search).get("uniAssembly")
const origin = new URLSearchParams(location.search).get("origin")
 if (uniAssembly==null) { history.push("/Home")}else{myCtx.getSearch()??myCtx.setSearchSt("All")} 
  

 if(origin!==null){uniAssembly=uniAssembly +'/' + origin } 

  const { error, isPending, data} = useFetch(root+'/api/Ass/'+uniAssembly,call)
  
 


  


  

  
 



 /// const name = new URLSearchParams(location.search).get("name")
  

  
  

  //data && LoadGeometry(data,id)
  //data &&  myCtx.updatemeshsArry(data.uniAssembly)


function call (data){
  
  myCtx.setSelectSt(null);
  myCtx.getSelectedClipeBoard().forEach(element => {
    if(element.updated_at!== "not")element.updated_at= null
  }); 
  LoadGeometry (data,myCtx)
 
  fetch(root+'/api/getChildren/'+data.uniAssembly.id ,{
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
     
    
    }

  })
        .then((res) => {
          if (!res.ok) {
            throw Error("could not fetch the data for that resource");
          }
          return res.json();
        })
        .then((d) => {
       
          data.partInsts.forEach((p, i) =>{
            d.partInsts[i] && ( p.gChildren=d.partInsts[i].gChildren)

          })
          data.uniAssembly.fork=d.fork
myCtx.geoUpdate()

        }
        
        ).catch((err) => { console.log(err.message) });

    } 

return (
   
    <div >
  
      {error && (console.log(error))}
      {isPending && (<h2 className="loading">Loading ...</h2>)}
   
      {data &&  <div > 
     {} 
       {/*     <SidebarMenu  meshsArry={data.partInsts} Assy={data.uniAssembly} go={go} />*/  } 
         
       
   <SideBar meshsArry={[...data.partInsts,...myCtx.getSelectedClipeBoard()]} 
   uniAssembly={data.uniAssembly} 
   Assy={data.parentAss}
   datum={data.datum} /> 
 
          <ThreeCanvas
            meshsArry={[...data.partInsts,...myCtx.getSelectedClipeBoard()]} 
          uniAssembly={data.uniAssembly}
           Assy={data.parentAss}  
       datum={data.datum}/> 

        </div>
      }

    </div>
  );
};

export default Details;

{/* <MyStatus.Consumer> 
{({getStatus}) => ( <h2 className="loading">{getStatus()}</h2>)}
</MyStatus.Consumer>  */}
