import MyContext ,{root} from "../../store/MyContext";
import {Hook,Swap,FaceSVG} from "../../store/SVGFiles";
import { useContext ,useRef} from "react";
import { Link ,useHistory} from "react-router-dom";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
import { MTLLoader } from "three/examples/jsm/loaders/MTLLoader";
import {mergeVertices,getNormal} from '../../Components/myMergeVertices'
import { PLYLoader } from "three/examples/jsm/loaders/PLYLoader";
import * as THREE from "three";
import PrecisionManufacturingOutlinedIcon from '@mui/icons-material/PrecisionManufacturingOutlined';
import {mergeGeometry} from '../../Components/MakeHollow'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
function SidebarContent({uniAssembly,meshsArry}) {
  const myCtx = useContext(MyContext);
  const history = useHistory();
  const titleRef = useRef()
  if(myCtx.grabSt!==null && myCtx.grabSt.id !== undefined ){
    if (window.confirm("Fork" + myCtx.grabSt.name + " to here ?"  ))
    {
      uniAssembly.fork && uniAssembly.fork.push(myCtx.grabSt)


      createFork({part:uniAssembly.id,origin:myCtx.grabSt.id},myCtx) 
    
  
    }
  }

    return (
        <div  >




                    <div className="dropdown" style={{right:"10px",position:"absolute" ,top:225 }} >
                 <img src={'./icon/ForkAdd.svg' }  className="dropbtn"   alt="NO"  style={{width:18, padding:2}} onClick={() =>{}} /> 
              <div className="dropdown-content"  
              //style={{left:"-160px"}}
               >
<span >Fork as New copy</span>
<div onClick={()=>{myCtx.isEditOn && myCtx.setGrabSt("request")}} >Fork to Existing</div>

</div>
</div>
     
          
            
{uniAssembly.fork && <>

<div className="row" style={{position:"absolute", left:"0px",top:130,/*maxWidth:200*/}} >

  <div className="row__posters">


 
  {uniAssembly.fork.map( (p, index) => <div  key={index} className="row__poster"
   
  onPointerEnter={(e) =>{e.target.scrollIntoView({inline:"center"})}} 
  onClick={(e) =>{history.push("/?uniAssembly=" + p.id) } } 
  
  > <img src={root+'/images/'+p.picFile} alt="Assembly"  className="row__imgs"
onError={imageOnErrorHandler}   /> {p.name}</div>
  )}


  </div>
  </div>
  <div style={{right:"24px",position:"absolute" ,top:170,left:110 }} ><Swap/></div>

</>}

<div className="assembly" >
          <div className="name" ref={titleRef}>{uniAssembly.name}</div> 
              {/* <PrecisionManufacturingOutlinedIcon className="itemWidget"  style={{right:20}} onClick={() =>{geoLoad(uniAssembly,myCtx,meshsArry)}} />  */}
           
            <div className="itemWidget"   style={{right:10,bottom:80,padding:2}} onClick={() =>{geoLoad(uniAssembly,myCtx,meshsArry)}} ><Hook/></div>
            <div >Part number:{uniAssembly.id}  </div>
              
              {myCtx.isEditOn ? (<>
             
              <select defaultValue={uniAssembly.origin } onChange={(e) =>{uniAssembly.origin=e.target.value;uniAssembly.updated_at="not";
               if (window.confirm("Do you want this to be the default link ?" )){
               
                
                
             
                titleRef.current.innerHTML="Saving ...." 
                savePart(uniAssembly,(e)=>{titleRef.current.disabled=e;titleRef.current.innerHTML=uniAssembly.name},myCtx,history)
                }
              
              }} >
              <option  value={uniAssembly.id}>Self</option>
              <option  value={uniAssembly.origin}>{uniAssembly.name}</option>
              {uniAssembly.fork && uniAssembly.fork.map( (p, index) =>

              <option  key={index} value={p.origin}>{p.name}</option>
                               )} 
            </select>

             {/* <img src={uniAssembly.picFile}  alt="Assembly" className="Prvimages"  onError={imageOnErrorHandler}   />  */}

             {/* <ContentPasteIcon className="itemWidget"  style={{right:20}} onClick={() =>{meshsArry.push({...myCtx.getClipeBoard(uniAssembly)});myCtx.geoUpdate()}} /> */}
             {/* <AttachFileIcon  style={{'right': '20px','position':'absolute','cursor': 'pointer'}}>  */}
         
         
       
        {/* </AttachFileIcon>  */}
        </>):<div >Original:{uniAssembly.origin} </div>}
  <div className="content" style={uniAssembly.updated_at==="not" ? {color:'orange','fontStyle':'italic'}:{overflow: 'hidden'} }> 
  <div className="date"  > {uniAssembly.updated_at==="not"?"Not Saved!" :new Date(uniAssembly.updated_at).toISOString().split('T')[0]} </div>      
<div >
<div className="author"  style={{position:"absolute",  hight:40,width:40}}>{uniAssembly.owner.pic==null?<FaceSVG/>:<img src={root+'/images/'+uniAssembly.owner.pic}  className="photo"  alt="Owner" />}</div>

<div style={{marginLeft:40}} >{uniAssembly.owner.name}</div>
<div className="rating" style={{marginLeft:40}}> $  </div>
</div>


</div>
        
             
        </div>
        
    <div className="branch"/>
       
         
        </div>
    )
}

export default SidebarContent




function geoLoad(uniAssembly,myCtx,meshsArry){
  
  const d ={...uniAssembly}
  delete d.geometry;
  (d.color==null||d.color===16777215) && (d.color= Math.random() * 0xffffff)

  void new PLYLoader().load( root+'/PLY/'  + d.modelFile,onLoad,()=>{},onError);
    


function onLoad(geome,) {
      console.log("S")
      
        geome=getNormal(geome)
        geome = mergeVertices(geome);
        //geome.computeVertexNormals();

        geome.computeBoundingSphere();
        const a = geome.boundingSphere.center;
  
            d.x==null?d.x = a.x:a.x=0;
            d.y==null?d.y = a.y:a.y=0;
            d.z==null?d.z = a.z:a.z=0;
            geome.translate(-a.x, -a.y, -a.z);


        d.geometry = geome; 
      console.log("Geometry of Assembly loded");
      myCtx.setClipeBoard(d);myCtx.geoUpdate()
    }
function  onError(){
  d.x??(d.x=0); d.y??(d.y=0);d.z??(d.z=0)
  
    let text = "rep Gometray can't bee found ,do you want to generat one ? it'll takes sometime though";
    if (window.confirm(text) == true) {
      console.log("You pressed OK!");
      d.geometry= mergeGeometry(meshsArry)

      d.geometry.computeBoundingSphere();
        const a =  d.geometry.boundingSphere.center;
  
            d.x==null?d.x = a.x:a.x=0;
            d.y==null?d.y = a.y:a.y=0;
            d.z==null?d.z = a.z:a.z=0;
            d.geometry.translate(-a.x, -a.y, -a.z);
      
      console.log("rep Gometray created");
      myCtx.setClipeBoard(d);myCtx.geoUpdate()

    } else {
     console.log("You canceled!");
   
  





  
  d.geometry= new THREE.BufferGeometry();
  const vertices = new Float32Array( [
    -.01, -.01,  .01,
     .01, -.01,  .01,
     .01,  .01,  .01,
  
     .01,  .01,  .01,
    -.01,  .01,  .01,
    -.01, -.01,  .01
  ] );
  d.geometry.setAttribute( 'position', new THREE.BufferAttribute( vertices, 3 ) );
  d.geometry.setAttribute( 'normal', new THREE.BufferAttribute( vertices, 3 ) );
  d.geometry.computeBoundingSphere();

  console.log("new Geometry created");
  myCtx.setClipeBoard(d);myCtx.geoUpdate()
  
}

}

    }




    
    function imageOnErrorHandler(e){
      e.currentTarget.src = ('./icon/Fallback.svg')
     } 



     function createFork(fork,myCtx){
      //const Fork = {...Dt};
     
  
  console.log(JSON.stringify(fork));  
  
      fetch(root+"/api/createFork",{
          method: "POST",
          headers: { "Content-Type": "application/json", 'Authorization': 'Bearer ' + myCtx.user.token },
          body: JSON.stringify(fork)
        }).then((res) => {
          if (!res.ok) {console.log(res.statusText); throw Error("can't create datum");}
          return res.json();
        }).then((data) => {
          // Dt.id=data.id;
          // Dt.updated_at=data.updated_at;
          // Dt.created_at=data.created_at;
          // setIsLoading(false)
    })
        .catch((err) => { console.log(err.message) });
      
    
    }
    function savePart(part,setIsLoading,myCtx,history){
      if (myCtx.user.name===undefined){alert("Sign In to save");setIsLoading(false)}
    else{
        const partInst = {...part}
        delete partInst.geometry;
        delete partInst.updated_at;
        delete partInst.created_at;
        delete partInst.id;
        //delete partInst.modelFile;
        
        //const token = auth?.token;
        fetch(root+"/api/savePart/" + part.id,{
            method: "PUT",
            headers: { "Content-Type": "application/json",
            'Authorization': 'Bearer ' + myCtx.user.token
           },
            body: JSON.stringify(partInst),
          }).then((res) => {
            if (!res.ok) {throw Error("can't updat part");}
            return res.json();
          }).then((data) => {
            part.updated_at=data.updated_at;

           setIsLoading(false)
history.push("/?uniAssembly=" + part.id+ "&origin=" + part.origin)
           })
        .catch((err) => { console.log(err.message) ;setIsLoading(false)
          
        });
      
    
    }}