import MyContext ,{root} from "./MyContext";
import { useContext } from "react";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
import { MTLLoader } from "three/examples/jsm/loaders/MTLLoader";
import {mergeVertices,getNormal,reduceForSave} from '../Components/myMergeVertices'
import { PLYLoader } from "three/examples/jsm/loaders/PLYLoader";
import * as THREE from "three";
import PrecisionManufacturingOutlinedIcon from '@mui/icons-material/PrecisionManufacturingOutlined';
import MakeHollow, {mergeGeometry} from '../Components/MakeHollow'
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { Link ,useHistory} from "react-router-dom";
export default function Upload() {
  const history = useHistory();
    const myCtx = useContext(MyContext);
 
  var mtlUrl="a"
  var objUrl="a"
  var plyUrl=""
  return (
<div className="dropdown">
    <PrecisionManufacturingOutlinedIcon    className="dropbtn"></PrecisionManufacturingOutlinedIcon > 
    <div className="dropdown-content">      
    <label  onClick={e=>NewProject(history,myCtx)}>
    *New Project   
    </label>
    <label>
        <input 
    type="file"  accept=".mtl, .obj" multiple 
    onChange={(e) => {
    
      if(e.target.files.length > 0 ){
      Array.from(e.target.files).forEach(file => {
      (file.name.slice(file.name.length - 3)==="mtl")?
      (mtlUrl=  (window.URL || window.webkitURL).createObjectURL(file)):
      (objUrl=  (window.URL || window.webkitURL).createObjectURL(file))
      })
      OBJInsert1(mtlUrl,objUrl,myCtx)} }} /> 
   Import OBJ 
   </label>
  
   <label>
   <input 
    type="file"  accept=".bin,.txt, .ply"  
    onChange={(e) => {                  
      if(e.target.files.length > 0 ){
       
      plyUrl= (window.URL || window.webkitURL).createObjectURL(e.target.files[0])      
      PLYInsert1(plyUrl,myCtx);
    }}}   /> 
     Import PLY
    </label>
    <label>
    Import JT   
    </label>
    <label>
    Import STL   
    </label>
  
   </div>
</div>
  )
}



function OBJInsert1(mtlUrl,objUrl,myCtx) {
    void new MTLLoader().load(mtlUrl, function (materials) {
      materials.preload();
      void new OBJLoader().setMaterials(materials).load(objUrl, AfterLoad);
  
      function AfterLoad(geomeMesh) {
       
        
        geomeMesh.children.forEach((meshIns,key) => {

        console.log("OBJ:" + meshIns.geometry.getAttribute( 'position' ).count)
        meshIns.geometry.computeBoundingSphere();
          meshIns.geometry=reduceForSave(meshIns.geometry)

        console.log("reduced:"+ meshIns.geometry.getAttribute( 'position' ).count+":in"+meshIns.geometry.getIndex().count)
// if (meshIns.geometry.getAttribute( 'position' ).count > 10000){
//   meshIns= MakeHollow(meshIns) }else{}

        meshIns.geometry=getNormal(meshIns.geometry)
       
        meshIns.geometry = mergeVertices(meshIns.geometry);
     
        meshIns.geometry.computeBoundingSphere();
      
        const a = meshIns.geometry.boundingSphere.center;
        
       
       const x=a.x
       const y=a.y
       const z=a.z
       meshIns.geometry.translate(-a.x, -a.y, -a.z);
        myCtx.setClipeBoard({id:key
          ,origin:0
          ,geometry:meshIns.geometry
          ,uniAssemblyID:null
          ,aX: null
          ,aX_nd: null
          ,aX_st: null
          ,aY: null
          ,aY_nd: null
          ,aY_st: null
          ,aZ: null
          ,aZ_nd: null
          ,aZ_st: null
          ,color: meshIns.material.color
          ,created_at:  "not"
          ,updated_at:  "not"
          ,extHight: null
          ,extHightEnd: null
          ,extHightSart: null
          ,extLength: null
          ,extLengthEnd: null
          ,extLengthSart: null
          ,extWidth: null
          ,extWidthEnd: null
          ,extWidthSart: null
          ,modelFile: null
          ,picFile:null
          ,name: meshIns.name
        
       
          ,x_nd: null
          ,x_st: null
          
          ,y_nd: null
          ,y_st: null  
          
          ,z_nd: null
          ,z_st: null
          ,x:x
          ,y:y
          ,z:z
        }) 

        
       
      
        // meshIns.geometry.computeVertexNormals();
        myCtx.geoUpdate();
        
    });
           
        
          
          
        
      }
    });
  }
  
  
  function PLYInsert1(plyUrl,myCtx) {
  
  
    void new PLYLoader().load(plyUrl, AfterLoad);
    function AfterLoad(geome) {
      if (geome !== undefined && geome !== null) {
        geome=getNormal(geome)
geome = mergeVertices(geome);
        
      geome.computeBoundingSphere();
      const a = geome.boundingSphere.center.negate();
      
        
        
       
       const x=a.x
       const y=a.y
       const z=a.z
      
        
      
  
        
   
        geome.translate(-a.x, -a.y, -a.z);
            myCtx.setClipeBoard({id:0
          ,geometry:geome
          ,uniAssemblyID:null
          ,origin:0
          ,aX: null
          ,aX_nd: null
          ,aX_st: null
          ,aY: null
          ,aY_nd: null
          ,aY_st: null
          ,aZ: null
          ,aZ_nd: null
          ,aZ_st: null
          ,color: null
          ,created_at:  "not"
          ,extHight: null
          ,extHightEnd: null
          ,extHightSart: null
          ,extLength: null
          ,extLengthEnd: null
          ,extLengthSart: null
          ,extWidth: null
          ,extWidthEnd: null
          ,extWidthSart: null
          ,modelFile: null
          ,name:"ply"
          ,updated_at:  "not"
          ,picFile:null
          ,x_nd: null
          ,x_st: null
          
          ,y_nd: null
          ,y_st: null  
          
          ,z_nd: null
          ,z_st: null
          ,x:x
          ,y:y
          ,z:z
        
          
        }) 
      
        
      
          
        // x.geometry.computeVertexNormals();
            myCtx.geoUpdate();
          }
    }
    
  }
  
  function NewProject(history,myCtx) {
    if (myCtx.user.name===undefined){alert("Sign In to creat")}
    else{
    
    let Workbench = prompt("Please enter your project name :", "New Assembly");
    if (Workbench !== null && Workbench !== "") {
    
    
   
    const partInst ={name:Workbench,origin:0,author:myCtx.user.id}

    fetch(root+"/api/createInst",{
      method: "POST",
      headers: { "Content-Type": "application/json",
      'Authorization': 'Bearer ' + myCtx.user.token},
      body: JSON.stringify(partInst),
    }).then((res) => {
      if (!res.ok) {throw Error("can't create part");}
      return res.json();
    }).then((data) => {history.push("/?uniAssembly=" + data.id) })
      .catch((err) => { console.log(err.message) });

}

 }}
