import MyContext,{root} from "./store/MyContext";
import { Canvas, useThree  } from "@react-three/fiber";
import { useHistory } from "react-router-dom";
import { PerspectiveCamera,SpotLight,TransformControls } from "@react-three/drei";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import React, { useContext, useRef,useState,useEffect,forwardRef,Suspense} from "react";
import * as THREE from "three";
import Outline from "./Components/outline";
import ShowModel from "./Components/ShowModel";
import MakeHollow from "./Components/MakeHollow";
import SquareFootIcon from '@mui/icons-material/SquareFoot';

//import ScreenshotButton from "./Components/Screenshot";

import PartsDraw from "./Components/PartsDraw";
import Datum from "./Components/Datum";

import { HexColorPicker   } from "react-colorful";
import SettingsIcon from '@mui/icons-material/Settings'
import ZoomFit from   "./Components/ZoomFit";

import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
const ThreeCanvas = ({ meshsArry, Assy,uniAssembly,datum}) => {




const myCtx = useContext(MyContext);
let pos = new THREE.Vector3(0.3,0.3,0.3);
let amieCount=0;
  const history = useHistory();
  var timeoutID =null;

  const AcvMech={};
  const assRef= useRef();
  
  const orbitControls = useRef(null)
  const testref = useRef(null)
  const refZoomFit = useRef(null)
  const refDatum= useRef(null)
  const transformControls = useRef(null)
  const screenshot  = useRef(null)
  

  const light = useRef();
  
  const p={xpP:0};


 useEffect(() => {  
const t= (transformControls.current !== undefined )? 1000:0

setTimeout(() => {
        (uniAssembly.geometry  !== undefined )&&(uniAssembly.geometry  !== null )  &&
  (myCtx.selectSt == null) && (myCtx.isexploded()?whenSelected(2):whenSelected(1.1)); 


  if (transformControls.current) {

    //(myCtx.selectSt === null)&&
     transformControls.current.detach () 

    const { current: controls } = transformControls

      const callback = (event) => {
        if(myCtx.selectSt !== null && !event.value){
          const e=transformControls.current.object
          if (myCtx.isexploded()){
          meshsArry[e.name].x_st=meshsArry[e.name].x-e.position.x
          meshsArry[e.name].y_st=meshsArry[e.name].y-e.position.y
          meshsArry[e.name].z_st=meshsArry[e.name].z-e.position.z
          }else{
          meshsArry[e.name].x=e.position.x
          meshsArry[e.name].y=e.position.y
          meshsArry[e.name].z=e.position.z
        }meshsArry[e.name].updated_at="not"
      
      };
         orbitControls.current.enabled = !event.value
      }
     
    
      
      controls.addEventListener('dragging-changed', callback)
      //controls.addEventListener('end', onEnd)
      return () => controls.removeEventListener('dragging-changed', callback)
    }
  } ,t);
  
    
 })
    
 //(transformControls.current)&& transformControls.current.detach () 

//  useEffect(() => {  
// },[myCtx,uniAssembly.geometry,myCtx.selectSt]) 

/*
 function whenSelected(s,selection=myCtx.selectSt) {

   if (orbitControls.current !== null) {
   
     zoomCameraToSelection(
       orbitControls.current.object,
       orbitControls.current,
       //(myCtx.selectSt !== null?assRef.current.m[myCtx.selectSt]:uniAssembly),
       
        (selection!== null?AcvMech.e?.children[selection]:uniAssembly),
      s,
      ()=>{},
    ()=>{assRef.current.Update();
      (meshsArry.length===0)&&(uniAssembly.picFile==null)&& (screenshot.current(uniAssembly));
    }
     );
// 
refDatum.current.Update(!myCtx.isexploded())
     //myCtx.setCenter (orbitControls.current.target)
   
  }
}
  */
function whenSelected(s,selection=myCtx.selectSt) {
  refZoomFit.current.Update(
    orbitControls.current,
    (selection!== null?AcvMech.e?.children[selection]:uniAssembly),
      s
  )
 
}

  function animating(e){
assRef.current.on(e)
refDatum.current.Update(!myCtx.isexploded())
}



  function editClick(e) {
     
    if (myCtx.selectSt !== null) {
    transformControls.current.attach (AcvMech.e.children[myCtx.selectSt])

  }
    transformControls.current.setMode(e.target.id)

  }
  function asscClick(e) {uniAssembly.uniAssemblyID !== null && history.push("/?uniAssembly=" + Assy.id);}






   
        return (<>
        
          <div className="Canvas">  
          
      <div className="wdgt" style={{top:70}} ref={testref} onClick={(e) =>{myCtx.setEditOn(!myCtx.isEditOn)}} ><SettingsIcon/><span className="tooltip">Edit On/Off</span></div>
      <div className="wdgt" style={{top:130}} onClick={(e) =>{screenshot.current(null)}} onContextMenu={(e) =>{screenshot.current(uniAssembly)}}><CameraAltIcon/><span className="tooltip">Snapshot</span></div>
      <div className="wdgt" style={{bottom:30}} onClick={(e)=>whenSelected(1.1)}      > <ZoomOutMapIcon/> <span className="tooltip">Fit Zoom</span></div>
      <div className="wdgt" style={{bottom:80,pointerEvents:'none'}}  > <img className="dropbtn" src={"/icon/explode-svgrepo-com.svg"} style={{width:35,padding:2}} alt={"explode"} ></img><span className="tooltip">Exploded View</span></div>
      
      <div className="wdgt" id = "translate" onClick={editClick}  style={{top:210}} ><div id = "translate" className="translate"  /><span className="tooltip">Translate</span></div>
      <div className="wdgt" id = "rotate" onClick={editClick} style={{top:260}} > <div   id = "rotate" className="rotate" /><span className="tooltip">Rotate</span></div>
      <div className="wdgt" id = "scale" onClick={editClick} style={{top:310}} ><div id = "scale" className="scale"  /><span className="tooltip">Scale</span></div>
      
      <div className="wdgt"  onClick={e=>myCtx.setDatumOn("dimension") } style={{top:380}} ><SquareFootIcon/><span className="tooltip">Dimensions</span></div>
      <div className="wdgt"  onClick={e=>myCtx.setDatumOn("documentation") } style={{top:440}} ><img  src={"/icon/pin.svg"}  alt={"put a note"} /><span className="tooltip">Put a note</span></div>
  
    {/* */}

            
      { (myCtx.selectSt!==null && myCtx.isEditOn )&&(<>
      <div className="editPanel">
        
     

      < HexColorPicker  color={meshsArry[myCtx.selectSt].color!==null &&
      ( meshsArry[myCtx.selectSt].color.toString(16))

      }
      onChange={ (e)=> {AcvMech.e.children[myCtx.selectSt].material.color.set(e)}}
      onBlur={ (e)=> {meshsArry[myCtx.selectSt].color= AcvMech.e.children[myCtx.selectSt].material.color.getHex ()  ;}}
      onFocus={ (e)=> {meshsArry[myCtx.selectSt].updated_at="not";myCtx.geoUpdate()}}
      />

      


          </div>
            </>)}

        
            {/* //className="widget" 
            //  onClick={ (e)=>{
            //   meshsArry[myCtx.selectSt].geometry= MakeHollow(AcvMech.e.children[myCtx.selectSt])
            //   meshsArry[myCtx.selectSt].updated_at="not"
            //   meshsArry[myCtx.selectSt].modelFile=null
            //   //myCtx.geoUpdate()
            //   }} */}
               
          
             
            
             

         
          <Canvas  
          //  mode="concurrent"  
            // camera={{
            //   far: 10,
            //   near: 0.01,
            //    position: [0.2, -0.2, 0.2],
            //  up: [0, 0, 1],
            // }}
          
          
        camera={{
          far: 10,
          near: 0.001,
          position:uniAssembly.x_nd!==null?[uniAssembly.x_nd,uniAssembly.y_nd,uniAssembly.z_nd]:[0.5, 0.3, -1],
         up: [0, 1, 0],
        }}
      >
        {/* <Stats showPanel={0} className="stats"  /> */}
        
       
           {/* <hemisphereLight intensity={0.1} />
           <ambientLight /> */}
             {/* <Suspense fallback={<Html Center><h1>Loading ...</h1></Html>}></Suspense> */}
            <pointLight  ref={light} 
            position={uniAssembly.x_nd!==null?[uniAssembly.x_nd,uniAssembly.y_nd,uniAssembly.z_nd]:[0.5, 0.3, -1]} 
            ></pointLight >
              
           <CameraController  ref={orbitControls} enableDamping={true}   rotateSpeed={3} panSpeed={3}  
          // target={myCtx.center()} 
           onStart={(e)=>{ animating(false)}}
             //assRef.current.Update(false)}}
            
           onEnd={(e)=>{animating(true)}} 
          onChang={(e)=>{
           
            light.current.position.copy(orbitControls.current.object.position)
           }}
        dynamicDampingFactor={ .9}  // TrackballControls
     
           > 
          
       
           
             </CameraController>
        <TransformControls ref={transformControls}   makeDefault  > </TransformControls> 

       <mesh >{(meshsArry.length===0) ?(uniAssembly.geometry && <ShowModel uniAssembly={uniAssembly} asscClick={asscClick} myCtx={myCtx} datum={datum} > </ShowModel>)
     
          

          : (Assy?.geometry && uniAssembly.geometry && myCtx.selectSt == null&& <Outline Assy={Assy} uniAssembly={uniAssembly} click={asscClick} myCtx={myCtx} />)
        }
        
        </mesh> 

       


        <PartsDraw
        datum={datum}
      whenSelected={whenSelected}
          meshsArry={meshsArry}
          uniAssembly={uniAssembly}
         ref={assRef}
          camera={orbitControls.current==null?undefined:orbitControls.current.object  }
          AcvMech = {AcvMech}
          myCtx={myCtx}
          editOnPart={(e)=> {transformControls.current.attach(e);editClick({target:{id:"translate"}})  }} 
          history={history}
          p={p}
          animating={animating}
        ></PartsDraw>
    
    {/* <Dimensions
        meshsArry={meshsArry}
        AcvMec = {AcvMech.e!==undefined?AcvMech.e.children:[]}
        myCtx={myCtx}

        // onPointerOver={hover}
        // onPointerOut={unHover}
        // onPointerMissed={PointerMissed}
        // onClick={PartClick}
        // onDoubleClick={onDClick}

        ></Dimensions> */}
<Datum datum={datum} myCtx={myCtx} center={uniAssembly.geometry&&uniAssembly.geometry.boundingSphere.center}
ref={refDatum}
/>
<Screenshot ref={screenshot} myCtx={myCtx}/>
<ZoomFit ref={refZoomFit}  
 end={()=>{animating(true);
          orbitControls.current?.update();
        (meshsArry.length===0)&&(uniAssembly.picFile==null)&& screenshot.current(uniAssembly);
        (myCtx.selectSt!==null) && (meshsArry[myCtx.selectSt].picFile==null) && screenshot.current(meshsArry[myCtx.selectSt]);
        } } 
          
 start={()=>{animating(false);} }
/>
      </Canvas>
      
    </div>
    </>);
};

export default ThreeCanvas;


const  CameraController = React.forwardRef(({onEnd,onStart,onChang},ref) =>{
  const { camera ,gl } = useThree();
  useEffect(
     () => {
      ref.current = new OrbitControls(camera, gl.domElement);
     ref.current.addEventListener('change', onChang)
      ref.current.addEventListener('end', onEnd)
      ref.current.addEventListener('start', onStart)

     return () =>{ ref.current.removeEventListener('change', onChang)
       ref.current.removeEventListener('end', onEnd)
       ref.current.removeEventListener('start', onStart) 
     }
       //  // ref.current.dispose();
    // }
        // controls.minDistance = 3;
        // controls.maxDistance = 20;
        
        // return () => {
        //  
        // };
     },
    [camera, gl]
  );
  return null;

  
});

//  //function CameraController() {
// const  CameraController = React.forwardRef((props, ref) =>{
 
 
//   const { invalidate, camera, gl } = useThree()
//   useEffect(() => {
//     ref.current.addEventListener('change', invalidate)
//     return () => ref.current.removeEventListener('change', invalidate)
//   }, [])
//   return <OrbitControls ref={ref} args={[camera, gl.domElement]} />
// }) 

const Screenshot=  React.forwardRef(({myCtx},ref) =>{

  const { gl, scene, camera } = useThree()


  ref.current =function (item) {
    if (item) {
   let z= new THREE.Vector2() 
   gl.getSize(z)
  const x_nd= camera.position.x-item.geometry.boundingSphere.center.x
  const y_nd= camera.position.y-item.geometry.boundingSphere.center.y
  const z_nd= camera.position.z-item.geometry.boundingSphere.center.z
  
  

  gl.setSize(204,165,false)//gl.setSize(200*z.x/z.y,200,false)  
   gl.setViewport ((z.y*204-z.x*165)/z.y/2,0,165*z.x/z.y,165) 
  // gl.setPixelRatio (1)
  //gl.setScissor (0,0,100,100)
 // gl.setViewport ( (z.y-z.x)/z.y*110/2,0,204,204*z.y/z.x) 
//gl.setScissor (0,0,200,z.y)

  gl.render(scene, camera)
//(z.x-200*z.x/z.y)/2
  //gl.setViewport ((z.x-z.y)/2,0,(z.x-z.y)/2+z.y,z.y)
  // gl.toneMapping = THREE.ACESFilmicToneMapping
  // gl.toneMappingExposure = 0.6
  //gl.outputEncoding = THREE.sRGBEncoding
  
  gl.preserveDrawingBuffer = true
  
 
  gl.domElement.toBlob(
    function(blob) {
     
      var a = document.createElement('a')
      var url = URL.createObjectURL(blob)
      a.href = url
      // a.download = 'canvas.jpg'
      // a.click()
 
      //item.picFile=url
       
      var file = new File([blob],'canvas.jpg');
      var data = new FormData();
      data.append("x_nd", x_nd);
      data.append("y_nd", y_nd);
      data.append("z_nd", z_nd);
      data.append("File", file);
      data.append("Name", "canvas.jpg");

      fetch(root+'/api/uploadImage/'+item.id, {
        method: "POST",
        headers: {
        'Authorization': 'Bearer ' + myCtx.user.token},
      //  headers: {'Content-Type': 'multipart/form-data'},
        body: data,
      }).then((res) => {
        if (!res.ok) {throw Error("can't create mige");}
        return res.json();
      }).then((d) => {item.picFile=d.picFile})
      .catch((err) => { console.log(err.message) });

    },
    'image/jpg',
    0.9
  )
   gl.setSize(z.x,z.y,false)
 // gl.resetGLState ( )
  gl.preserveDrawingBuffer =false
 
}else{
  
  let z= new THREE.Vector2() 
  gl.getSize(z)
  
  gl.setSize(2600,2000,false)//gl.setSize(200*z.x/z.y,200,false)  
  gl.setViewport ((z.y*2600-z.x*2000)/z.y/2,0,2000*z.x/z.y,2000) 
gl.render(scene, camera)
  gl.preserveDrawingBuffer = true
  gl.domElement.toBlob( 
    function(blob) {
    gl.render(scene, camera)
    var a = document.createElement('a')
    var url = URL.createObjectURL(blob)
    a.href = url
    a.download = 'canvas.jpg'
    a.click()
    window.URL.revokeObjectURL(url);
}, 'image/jpg',
0.9

)
gl.setSize(z.x,z.y,false)
gl.preserveDrawingBuffer =false

}}

 return null;
//   (<sprite {...props} scale={[.01, .01, .01]} onClick={ScreenShot}>
//     <spriteMaterial
//       attach="material"
//       color={'lightblue'}
//       depthWrite={false}
//       depthTest={false}
//       renderOrder={10000}
//       fog={false}
//       onClick={ScreenShot}
//     />
//   </sprite>
});
