import { mesh } from "@react-three/fiber";
import {  Html} from "@react-three/drei";
import { CSS2DRenderer } from "three/examples/jsm/renderers/CSS2DRenderer.js";
import * as THREE from "three";
import { useRef } from "react";
function ShowModel({uniAssembly, asscClick,onAfterRender,myCtx,datum}){
   const lastPoint={point:new THREE.Vector3( 0, 0, 0 ),object:{name:0}};
   const refLine= useRef();
   const refDimension= useRef();
   const hoverColor =0x2265A1
  const refmesh =[];
  let  clickCount=0;
console.log(uniAssembly.geometry)
  const points = [new THREE.Vector3( 0, 0, 0 ),new THREE.Vector3( 1, 1, 1 )];
  const planeGeom = new THREE.PlaneGeometry( 1, .001 );
  
   function datumClick(e){
      if( myCtx.isDatumOn==="dimension"){
      if( clickCount===0){points[0].copy(e.point)}

      if( clickCount===1){
        let title = prompt("Dimension will be set to :", refDimension.current.textContent );
        if (title !== null && title !== "") {
      datum.push({
      ax:e.point.x,
      ay:e.point.y,
      az:e.point.z,

      bx:points[0].x,
      by:points[0].y,
      bz:points[0].z,
      uniAssemblyID:uniAssembly.origin,
      title:title, 
      updated_at:"not",
      created_at:"not",
      type:"dimension"
    })
     }
     myCtx.setDatumOn(false)   
       
        }
       else
       { clickCount++; }
      }else{
        let title = prompt("Note set title:", "" );
        if (title !== null && title !== "") {
         
          datum.push({
            ax:e.point.x,
            ay:e.point.y,
            az:e.point.z,
            bx:e.point.x,
            by:e.point.x,
            bz:e.point.x,
        
            uniAssemblyID:uniAssembly.origin,
            title:title, 
            updated_at:"not",
            created_at:"not",
            type:"documentation"
          })
        }
        myCtx.setDatumOn(false) 
      }

}
  

function onPointerMove(e){
  
     lastPoint.point.copy(e.point)
     lastPoint.object.name= e.object.name
   if(clickCount===1){
  
    
     const b=points[0]
     const a=e.point
             refLine.current.quaternion.setFromUnitVectors(new THREE.Vector3(1,0,0).normalize(),new THREE.Vector3().subVectors(a,b).normalize());
             refLine.current.scale.x= a.distanceTo(b)
             refLine.current.scale.y=50 
             refLine.current.position.addVectors(a,b).multiplyScalar(0.5);
             refLine.current.scale.x < 1? ( refDimension.current.textContent= ((refLine.current.scale.x*1000).toFixed() + " mm" )):
              ((refDimension.current.textContent= (refLine.current.scale.x).toFixed(2)  + " m" ))
           
           }else{
             refLine.current.position.copy(e.point)
            
           }
    
 }

   return (<>
     
 
<mesh 

          onPointerMissed={ myCtx.isDatumOn && ((e)=>datumClick(lastPoint))}
          onPointerMove={myCtx.isDatumOn && onPointerMove}
          onClick={myCtx.isDatumOn && datumClick}
          //
         // onContextMenu={(e)=>myCtx.setDatumOn(!myCtx.isDatumOn)  }
      
          


geometry={uniAssembly.geometry} onAfterRender={onAfterRender}  onDoubleClick={asscClick} 
//position={[uniAssembly.x,uniAssembly.y,uniAssembly.z]} 
 >
<meshPhongMaterial  color={uniAssembly.color}  vertexColors={uniAssembly.color ==null?true:false}/>


 </mesh>

{myCtx.isDatumOn && <mesh geometry={planeGeom}ref={refLine} >
<meshStandardMaterial color={0xffff00} side= {THREE.DoubleSide} 
 opacity={
    0.5
 }
 transparent={true}
/>
<Html style={{pointerEvents:'none'}} ><label ref={refDimension} >Click to Measures </label></Html>

 
  
</mesh>}
 </>)}
 
export default ShowModel;